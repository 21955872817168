import * as React from "react";

const SVGSC2 = (props) => (
	<svg
		width={174}
		height={657}
		viewBox="0 0 174 657"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g opacity={0.1}>
			<circle
				cx={328.5}
				cy={328.5}
				r={230.577}
				stroke="#999A9C"
				strokeWidth={40}
			/>
			<circle
				cx={328.5}
				cy={328.5}
				r={308.5}
				stroke="#00A8E4"
				strokeWidth={40}
			/>
		</g>
	</svg>
);

export default SVGSC2;
