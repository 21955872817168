import { Link, useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../../components/layout';
import Button from 'react-bootstrap/Button';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { Helmet } from 'react-helmet';
import ImageSection from '../../components/image-section';
import ServiceHero from '../../components/service-hero';
import { GoPrimitiveDot } from 'react-icons/go';
import ServiceHeroMobile from '../../components/service-hero-mobile';
import SVGSC1 from '../../components/svg/sc1-svg';
import SVGSC2 from '../../components/svg/sc2-svg';
import SVGSC3 from '../../components/svg/sc3-svg';
import HTMLReactParser from 'html-react-parser';

const SCESPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Suspended-Ceilings-Hero-SCP" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      WCCImg: wpMediaItem(title: { eq: "Why-Choose-Colston-Image-SCP" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      CTSImg: wpMediaItem(title: { eq: "Ceilings-To-Suit-Image-SCP" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      schemaLogo: wpMediaItem(title: { eq: "Colston-Logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      SC: wpSuspendedCeiling {
        suspendedCeilingsFields {
          heroSection {
            btnText
            heading1
            heading1Body
            image {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            subheading
            title
          }
          uniqueRequirementsSection {
            body
            heading1
            heading1Body
            heading1Bullets {
              label
            }
            image {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            title
          }
          whyUseColston {
            body
            btnText
            image {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            subheading
            title
          }
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
  const WCCImg = data.WCCImg?.localFile.childImageSharp.gatsbyImageData;
  const CTSImg = data.CTSImg?.localFile.childImageSharp.gatsbyImageData;
  const SC = data.SC?.suspendedCeilingsFields;

  const serviceSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    '@id':
      'https://www.colston.ltd.uk/services/suspended-ceilings-in-east-sussex/#suspended-ceilings-in-east-sussex',
    image: `https://www.colston.ltd.uk${data.heroImg.localFile.publicURL}`,
    mpn: 'https://www.colston.ltd.uk/services/suspended-ceilings-in-east-sussex',
    sku: 'https://www.colston.ltd.uk/services/suspended-ceilings-in-east-sussex',
    description:
      'Expert suspended ceiling solutions tailored to meet your unique requirements. Discover how Colston can enhance your commercial or residential property',
    logo: `https://www.colston.ltd.uk${data.schemaLogo.localFile.publicURL}`,
    name: 'Colston Ltd',
    url: 'https://www.colston.ltd.uk',
    brand: {
      '@type': 'Organization',
      logo: `https://www.colston.ltd.uk${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.colston.ltd.uk${data.heroImg.localFile.publicURL}`,
      name: 'Colston Ltd',
      url: 'https://www.colston.ltd.uk',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      reviewCount: '57',
      ratingValue: '5',
      bestRating: '5',
      worstRating: '1',
    },
    // review: {
    // 	"@type": "Review",
    // 	url: "https://www.dentalhouseexeter.co.uk/reviews",
    // 	datePublished: "2022-03-21T17:58:29+00:00",
    // 	reviewBody:
    // 		"This is the best dental practice I have known. Not only in terms of expertise but the lovely sociability of every single member of the practice team.",
    // 	author: {
    // 		"@type": "Person",
    // 		name: "Caroline P",
    // 	},
    // 	reviewRating: {
    // 		"@type": "Rating",
    // 		ratingValue: "5",
    // 		bestRating: "5",
    // 		worstRating: "1",
    // 	},
    // 	publisher: {
    // 		"@type": "Organization",
    // 		name: "Dental House Exeter",
    // 		sameAs: "https://www.dentalhouseexeter.co.uk",
    // 	},
    // },
  };

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: '1',
        name: 'Home',
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        '@type': 'ListItem',
        position: '2',
        name: 'Services',
        item: {
          url: `${siteUrl}/services`,
          id: `${siteUrl}/services`,
        },
      },

      {
        '@type': 'ListItem',
        position: '3',
        name: 'Suspended Ceilings',
        item: {
          url: `${siteUrl}/services/suspended-ceilings-in-east-sussex`,
          id: `${siteUrl}/services/suspended-ceilings-in-east-sussex`,
        },
      },
    ],
  };

  return (
    <Layout>
      <div className='position-relative'>
        <Helmet>
          {' '}
          <script type='application/ld+json'>
            {JSON.stringify(breadcrumb)}
          </script>
        </Helmet>
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(serviceSchema)}
          </script>
        </Helmet>

        <GatsbySeo
          title='Suspended Ceilings | Create your perfect property with Colston'
          description='Expert suspended ceiling solutions tailored to meet your unique requirements. Discover how Colston can enhance your commercial or residential property'
          language='en'
          openGraph={{
            type: 'website',
            url: `${siteUrl}/services/suspended-ceilings-in-east-sussex`,
            title:
              'Suspended Ceilings | Create your perfect property with Colston',
            description:
              'Expert suspended ceiling solutions tailored to meet your unique requirements. Discover how Colston can enhance your commercial or residential property',
            images: [
              {
                url: `${data.heroImg?.localFile.publicURL}`,
                width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
                height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
                alt: `${data.heroImg?.altText}`,
              },
            ],
          }}
        />
        <SVGSC1
          style={{ zIndex: '-1' }}
          className='position-absolute d-none d-lg-block top-10 start-0'
        />
        <SVGSC2
          style={{ zIndex: '-1' }}
          className='position-absolute d-none d-lg-block top-30 end-0'
        />
        <SVGSC3
          style={{ zIndex: '-1' }}
          className='position-absolute d-none d-lg-block bottom-0 start-0'
        />
        <ServiceHero
          title={SC.heroSection.title}
          subTitle={SC.heroSection.heading1}
          description={HTMLReactParser(SC.heroSection.heading1Body)}
          image={
            SC.heroSection?.image.node?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          imageAlt={SC.heroSection?.image?.node?.altText}
          ctaText={SC.heroSection.subheading}
          btnText={SC.heroSection.btnText}
        />
        <ServiceHeroMobile
          title={SC.heroSection.title}
          subTitle={SC.heroSection.heading1}
          description={HTMLReactParser(SC.heroSection.heading1Body)}
          ctaText={SC.heroSection.subheading}
          btnText={SC.heroSection.btnText}
        />
        <section className='py-xl-10 pt-8'>
          <ImageSection
            title={SC.uniqueRequirementsSection.title}
            image={
              SC.uniqueRequirementsSection?.image.node?.localFile
                ?.childImageSharp?.gatsbyImageData
            }
            imageAlt={SC.uniqueRequirementsSection?.image?.node?.altText}
            text={
              <div>
                {HTMLReactParser(SC.uniqueRequirementsSection.body)}
                <h3>{SC.uniqueRequirementsSection.heading1}</h3>
                {HTMLReactParser(
                  SC.uniqueRequirementsSection?.heading1body || ''
                )}
                <ul className='help-list mt-4 ps-0'>
                  {SC.uniqueRequirementsSection.heading1Bullets.map((i) => (
                    <li className='d-flex align-items-center'>
                      <div
                        style={{
                          minWidth: '15px',
                          height: '15px',
                          borderRadius: '100%',
                        }}
                        className='bg-secondary d-flex justify-content-start me-3 ms-0 ps-0 '
                      />

                      <p className='text-start text-primary fw-bold fs-5 pb-0 mb-0'>
                        {' '}
                        {i.label}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            }
            imgSpan={6}
            textSpan={6}
            rounded='left-rounded'
            textPos='start--20 top-10'
            imgHeight='50rem'
            imgWidth='100%'
          />
        </section>
        <section className='py-xl-10 py-5 mb-xl-7'>
          <ImageSection
            title={SC.whyUseColston.title}
            image={
              SC.whyUseColston?.image.node?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            imageAlt={SC.whyUseColston?.image?.node?.altText}
            text={HTMLReactParser(SC.whyUseColston.body)}
            ctaText={SC.whyUseColston?.subheading}
            btnText={SC.whyUseColston?.btnText}
            order='last'
            imgSpan={6}
            textSpan={6}
            rounded='right-rounded'
            textPos='end--20 top-10'
            imgHeight='45rem'
            imgWidth='100%'
            cta={true}
            eHeight='100px'
            ctaPos='bottom--10 start--10'
          />
        </section>
      </div>
    </Layout>
  );
};

export default SCESPage;
